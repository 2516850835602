<template>
    <div class="info-page">
      <a href="/">&#8592; Back to homepage</a>

      <h1>Customer Service & Policies</h1>
      
      <section>
        <h2>Customer Service Contact Details</h2>
        <p>We are here to help! If you have any questions, concerns, or need assistance, please feel free to reach out to us!</p>
        <ul>
          <li><strong>Phone:</strong> 516-765-4280</li>
          <li><strong>Email:</strong> wantaghperformingartsbooster@gmail.com</li>
          <li><strong>Address:</strong> 3301 Beltagh Ave., Wantagh, NY 11793</li>
          <li><strong>Online Messaging Service:</strong> Facebook or Instagram Messaging</li>
        </ul>
      </section>

      <section>
        <h2>Membership Policy</h2>
        <p>Please note that memberships to the Wantagh Performing Arts Booster Club are non-refundable. When you become a member, you're making a commitment to support our organization and its mission. Here are some key points about our membership policy:</p>
        <ul>
            <li>Memberships are valid for one school year (July 1st through June 30th).</li>
            <li>The $26 membership fee is a one-time payment for the entire school year.</li>
            <li>Memberships cannot be transferred to another individual or family.</li>
            <li>If you have any questions about membership before signing up, please contact us.</li>
        </ul>
        <p>We appreciate your understanding and support. Your membership helps us continue to enhance the performing arts programs in our schools.</p>
       </section>
  
      <section>
        <h2>Return Policy and Process</h2>
        <p>We want you to be completely satisfied with your purchase from our concessions. If you are not, you can return the item(s) at the event/concert when it was purchased, same day. Other fundraisers may have different policies. Please see fundraiser details for more info.</p>
        <h3>Concession Refund Conditions:</h3>
        <ul>
          <li>Refunds will be processed in cash at the time of the concession. Items must be unopened and not consumed or the date is past expiration marked on the package.</li>
        </ul>
        <h3>Dispute Resolution:</h3>
        <ul>
          <li>If you have any disputes or issues with your items, please contact us. We aim to resolve all disputes amicably and promptly.</li>
        </ul>
      </section>
  
      <section>
        <h2>Cancellation Policy</h2>
        <p>If you need to cancel your fundraiser order, please contact us immediately. Each fundraiser may have different terms and conditions.</p>
      </section>
  
      <section>
        <h2>Legal or Export Restrictions</h2>
        <p>Not Applicable</p>
      </section>
  
      <section>
        <h2>Terms and Conditions of Promotions</h2>
        <h3>General Terms:</h3>
        <ul>
          <li>Promotions and Fundraisers are subject to availability and may only be offered at set times/dates.</li>
        </ul>
        <h3>Specific Promotion Terms:</h3>
        <ul>
          <li>See details on promotions for terms related to the specific promotion.</li>
        </ul>
        <p>For more information about our promotions, please contact us!</p>
      </section>
    </div>
  </template>
  
  <script>
  export default {
    name: 'InfoPage'
  }
  </script>
  
  <style scoped>
  .info-page {
    max-width: 800px;
    margin: 0 auto;
    padding: 2rem;
  }
  
  section {
    margin-bottom: 2rem;
  }
  
  h1, h2, h3 {
    color: #333;
  }
  
  ul {
    padding-left: 1.5rem;
  }

  a {
    color: #007bff;
    text-decoration: none;
  }
  
  a:hover {
    text-decoration: underline;
  }
  </style>